exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-book-a-table-js": () => import("./../../../src/pages/book-a-table.js" /* webpackChunkName: "component---src-pages-book-a-table-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-gifts-js": () => import("./../../../src/pages/corporate-gifts.js" /* webpackChunkName: "component---src-pages-corporate-gifts-js" */),
  "component---src-pages-gift-cards-faq-js": () => import("./../../../src/pages/gift-cards/faq.js" /* webpackChunkName: "component---src-pages-gift-cards-faq-js" */),
  "component---src-pages-gift-cards-js": () => import("./../../../src/pages/gift-cards.js" /* webpackChunkName: "component---src-pages-gift-cards-js" */),
  "component---src-pages-give-the-gift-of-merry-moments-together-js": () => import("./../../../src/pages/give-the-gift-of-merry-moments-together.js" /* webpackChunkName: "component---src-pages-give-the-gift-of-merry-moments-together-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-national-search-js": () => import("./../../../src/pages/national-search.js" /* webpackChunkName: "component---src-pages-national-search-js" */),
  "component---src-pages-ourvenues-js": () => import("./../../../src/pages/ourvenues.js" /* webpackChunkName: "component---src-pages-ourvenues-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-treat-your-teacher-js": () => import("./../../../src/pages/treat-your-teacher.js" /* webpackChunkName: "component---src-pages-treat-your-teacher-js" */)
}

